
.expertiseDetail{
  margin-top: 10vh;
}

.expertiseDetail .detailHeader{
  /* display: flex;
  justify-content: space-around; */
  padding-top: 4vh;
  padding-bottom: 2vh;
  padding-left: 0px !important;
  padding-right: 0px !important;

  
}
.expertiseDetail .detailHeader .leftSide{
  /* background-color: grey; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15vh;
}

.expertiseDetail .detailHeader .leftSide  h1{
  /* background-color: grey; */
  font-size: 45px;
}


.expertiseDetail .detailHeader .rightSide p{
  font-size: 18px;
  max-width: 700px;
  padding-left: 10vh;
}

.expertiseDetail .contentContainer{
  margin-left: 15vh;
  margin-top: 5vh;
  margin-bottom: 10vh;
  margin-right: 2vh;
  max-width: 600px;

}

.expertiseDetail .contentContainer h1{
  font-size: 45px;
  margin-bottom: 2vh;
  line-height: 50px;

}

.expertiseDetail .contentContainer p{
  font-size: 18px;


}


@media (max-width: 992px) {
  .expertiseDetail .contentContainer{
    margin-left: 3vh;
    margin-right: 2vh;
  }

  .expertiseDetail .detailHeader .leftSide {
    /* background-color: grey; */
   /* padding-left: 3vh; */
   padding-left: 3vh;
  }

  .expertiseDetail .detailHeader .leftSide  h1{
    /* background-color: grey; */
    font-size: 30px;
  }

  
  .expertiseDetail .detailHeader .rightSide p{
    /* font-size: 25px;
    max-width: 600px; */
    padding-left: 0px;
  }

  .expertiseDetail .detailHeader .rightSide{
    padding-left: 3vh;
  }

  .expertiseDetail .contentContainer h1{
    font-size: 30px;
    margin-bottom: 2vh;
  
  }
  
  /* .expertiseDetail .contentContainer p{
    font-size: 20px;
  
  } */
  
  
  .expertiseDetail .detailHeader .rightSide p{
    /* font-size: 20px; */
    max-width: 600px;
  }

  .expertiseDetail .detailHeader{
    padding-left: 2vh;
  }

  .expertiseDetail .detailHeader .leftSide{
    /* background-color: grey; */
    align-items: flex-start;
    /* margin: 2vh; */
  }
}

/* -----------------------------------------------------------
#Projects section
----------------------------------------------------------- */
.u-text-light-bottom-border {
  border-bottom: 3px solid;
}

#project .section-title h2 {
  text-align: center;
  font-size: 1.875rem;
  /* font-weight: normal; */
  padding: 0px;
}

@media (max-width: 992px) {
  #project .section-title h2 {
    font-size: 1.2rem;
  }
}

#project .section-title {
  padding-bottom: 50px;

}

.social-media a {
  color: black;
  line-height: 0;
  transition: 0.3s;
  margin-left: 0px;
  font-size: 13px;
  display: inline-block;
  background: white;
  line-height: 1;
  padding: 8px 0;
  border-radius: 100%;
  text-align: center;
  width: 30px;
  height: 30px;
  transition: 0.3s;
  margin-bottom: 7px;
}

.contactus #project .section-title {
  padding-bottom: 0px;
}

.contactus #project .section-title h2 {
  text-align: left;
}

.contactus .form-control {
  border-radius: 0px;
}

.contactus #message-box {
  width: 100%;
}

.social-media ul li {
  list-style: none;
}

.social-media ul {
  padding-left: 0px;
}

.contactus .submitbtn {
  padding: 2px 38px;
  border-radius: 0px;
}

.news #project .section-title {
  padding-bottom: 20px;
}

.news #project .section-title h2 {
  font-size: 1.5rem;
}

#project .portfolio-item {
  position: relative;
  margin-bottom: 20px;
}

#project .portfolio-item .portfolio-info {

  position: absolute !important;
  left: 12px !important;
  right: 12px !important;
  bottom: 0 !important;
  z-index: 3 !important;
  padding: 0px 10px !important;
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgb(0 0 0 / 46%)) !important;
}

#project .portfolio-item h4 {
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 3px;
  margin: 0px;
  color: #f5d654;
}

#project .portfolio-item p {
  margin-bottom: 5px;
  font-size: 0.875rem;
}

#project .portfolio-item .bilist {
  float: right;
  bottom: 0;
  position: absolute;
  right: 6px;
  font-size: 29px;
}

#project button.load-more {
  min-width: 170px;
  border-radius: 0px;
  padding: 3px 0px;
  margin-top: 30px;
  letter-spacing: 2px;
  font-weight: 600;
}

