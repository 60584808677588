/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Poppins", sans-serif;
  color: #ffffffab;
  background: #000000;

}

a {
  text-decoration: none;
  color: #f5d654c2;
}


a:hover {
  color: #f5d654c2;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

p {
  font-family: "Poppins", sans-serif;
}

/*--------------------------------------------------------------
# about us
--------------------------------------------------------------*/
main.aboutus h2 {
  font-weight: 500;
}

/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
.service-filter {
  justify-content: end;
}

.service-filter .filter-text {
  /* max-width: 100px; */
  text-align: center;
}

/*.service-filter .select {
  /* max-width: 200px; */
/* }

.service-filter label.control-label {
  font-size: 14px;
}


.service-filter select:focus {
  background: black;
  color: white;
  outline: none;
  border-radius: 0px;
  border: 1px solid #ffff0063;
  box-shadow: none;
}

.service-filter select {
  background: black;
  color: white;
  outline: none;
  border-radius: 0px;
  border: 1px solid #ffff0063;
  padding: 4px 6px;
}

.breadth.lightblackbg {
  background-color: #1a1a1a;
}

.div-bottom-devider {
  height: 3px;
  background: gray;
  margin: 70px 0px;
}

.u-text-light {
  color: #f5d654;
}

.section-title-divider {
  width: 181px;
  height: 3px;
  background: #f5d654;
  margin: 0 auto;
  margin-bottom: 20px;
}

/*------------------------------------------------------------
#breadcum images
------------------------------------------------------------*/
.breadcrumbs .page-header {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 50vh;
}

@media (max-width: 991px) {
  .breadcrumbs .page-header {
    min-height: 30vh;
  }
}

/*------------------------------------------------------------
#expertise
------------------------------------------------------------*/
#breadth .section-title p {
  font-size: 1.3rem;
  font-weight: normal;
  padding: 0px;
}

@media (max-width: 991px) {
  #breadth .section-title p {
    font-size: 1rem;
  }
}

/*------------------------------------------------------------
#commitment
------------------------------------------------------------*/
section.commitment {
  background-image: url(../slider/commitment.jpg);
  background-size: cover;
  position: relative;
}

#commitment .section-title h2 {
  font-size: 1.875rem;
  /* font-weight: normal; */
  padding: 0px;
}

@media (max-width: 992px) {
  #commitment .section-title h2 {
    font-size: 1.2rem;
  }
}

#commitment .align-middle {
  padding: 50px 0px;
}

@media (max-width: 992px) {
  #commitment .align-middle {
    padding: 00px 0px;
  }
}

/*------------------------------------------------------------
#experties44
------------------------------------------------------------*/
section#experties44 {
  background-color: #2d5e98;
  background-image: url(../slider/over44.jpg);
  background-size: cover;
  position: relative;
}

#experties44 .align-middle {
  padding: 50px 0px;
}

@media (max-width: 992px) {
  #experties44 .align-middle {
    padding: 00px 0px;
  }
}

#experties44 .section-title h2 {
  font-size: 1.875rem;
  font-weight: normal;
  padding: 0px;
}

@media (max-width: 992px) {
  #experties44 .section-title h2 {
    font-size: 1.2rem;
  }
}

span.u-text-light.experties44-devider {
  border-bottom: 3px solid;
}

/* -----------------------------------------------------------
#Projects section
----------------------------------------------------------- */
.u-text-light-bottom-border {
  border-bottom: 3px solid;
}

#project .section-title h2 {
  text-align: center;
  font-size: 1.875rem;
  /* font-weight: normal; */
  padding: 0px;
}

@media (max-width: 992px) {
  #project .section-title h2 {
    font-size: 1.2rem;
  }
}

#project .section-title {
  padding-bottom: 50px;

}

.social-media a {
  color: black;
  line-height: 0;
  transition: 0.3s;
  margin-left: 0px;
  font-size: 15px;
  display: inline-block;
  background: white;
  line-height: 1;
  padding: 10px 0;
  border-radius: 100%;
  text-align: center;
  width: 35px;
  height: 35px;
  transition: 0.3s;
  margin-bottom: 7px;
  pointer-events: none;
}

.contactus #project .section-title {
  padding-bottom: 0px;
}

.contactus #project .section-title h2 {
  text-align: left;
}

.contactus .form-control {
  border-radius: 5px;
}


.contactus #message-box {
  width: 100%;
  border-radius: 10px;
}

.social-media ul li {
  list-style: none;
  font-size: 15px;
}

.social-media ul li:hover a{
  background-color: #f1c50e;
}

.social-media ul li:hover{
  color: #f1c50e;
}

.social-media ul {
  padding-left: 0px;
}

.contactus .submitbtn {
  padding: 5px 38px;
  border-radius: 10px;
  font-weight: 700;
}

.news #project .section-title {
  padding-bottom: 20px;
}

.news #project .section-title h2 {
  font-size: 1.5rem;
}

#project .portfolio-item {
  position: relative;
  margin-bottom: 20px;
}

#project .portfolio-item .portfolio-info {

  position: absolute;
  left: 12px;
  right: 12px;
  bottom: 0;
  z-index: 3;
  padding: 0px 10px;
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgb(0 0 0 / 46%));
}

#project .portfolio-item h4 {
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 3px;
  margin: 0px;
  color: #f5d654;
}

#project .portfolio-item p {
  margin-bottom: 5px;
  font-size: 0.875rem;
}

#project .portfolio-item .bilist {
  float: right;
  bottom: 0;
  position: absolute;
  right: 6px;
  font-size: 29px;
}

#project button.load-more {
  min-width: 170px;
  border-radius: 0px;
  padding: 3px 0px;
  margin-top: 30px;
  letter-spacing: 2px;
  font-weight: 600;
}

/* -----------------------------------------------------------
#experties section
----------------------------------------------------------- */
#experties .section-title h2 {
  text-align: center;
  font-size: 1.875rem;
  /* font-weight: normal; */
  padding: 0px;

}

@media (max-width: 992px) {
  #experties .section-title h2 {
    font-size: 1.2rem;
  }
}

#experties .section-title {
  padding-bottom: 30px;
}

#experties .section-title-divider {
  width: 118px;
}

section.experties {
  background-image: url(../slider/image8.jpg);
  background-repeat: no-repeat;
  position: relative;
}

#experties .portfolio-info {
  color: #ffffff;
  background-color: #333333;
  padding: 15px 10px;
}

#experties .portfolio-info h3 {
  font-weight: 500;
  font-size: 1.25rem;
}

#experties .portfolio-info p {
  margin: 0px;
  font-size: 0.875rem;
  color: #ffffffb5
}

/* -----------------------------------------------------------
#clients section
----------------------------------------------------------- */
#clients {
  background-color: #1a1a1a;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #f1c50e;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #ffffffc9;
  line-height: 0;
}

.back-to-top:hover {
  background: #e1444d;
  color: #ffffffc9;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #f1c50e;
  transition: all 0.5s;
  z-index: 997;
  padding: 10px 0;
}

#header.header-scrolled {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 0px;
  background: #eebf04ef;
  border-bottom: 1px solid #37332a;
}

#header.header-inner-pages {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}

#header .logo a {
  color: #556270;
}

#header .logo img {
  max-height: 50px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px 0 10px 30px; */
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffffc9;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #000;
}

.navbar li {
  color: #f2f2f2c9;
  background-color: transparent;
  border-right: 1px solid white;
  padding: 0px 15px;
}

.navbar li:last-child {
  border: none;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #f5d654;
  padding: 8px 25px;
  margin-left: 30px;
  border-radius: 4px;
  font-weight: 400;
  color: #ffffffc9;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #ffffffc9;
  background: #e1444d;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #ffffffc9;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #f1c50e;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

header .social-links a {
  color: black;
  line-height: 0;
  transition: 0.3s;
  margin-left: 20px;
  font-size: 18px;
  display: inline-block;
  background: white;
  line-height: 1;
  padding: 8px 0;
  border-radius: 100%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #ffffffc9;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #ffffffc9;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(63, 73, 83, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #ffffffc9;
  overflow-y: auto;
  transition: 0.3s;
  height: fit-content;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #556270;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #f1c50e;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #ffffffc9;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #f1c50e;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background-color: rgba(63, 73, 83, 0.8);
  overflow: hidden;
  position: relative;
}

#hero .carousel,
#hero .carousel-inner,
#hero .carousel-item,
#hero .carousel-item::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .carousel-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#hero .carousel-item::before {
  content: "";
  background-color: rgba(30, 35, 40, 0.6);
}

#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  align-items: flex-end;
  bottom: 15%;
  top: 70px;
  left: 50px;
  right: 50px;
}

#hero .container {
  text-align: center;
}

#hero h2 {
  color: #ffffff;
  font-size: 1.25rem;
}

#hero h2.entry-title{
  background-color: #f1c50e;
  color: #000000;
  display: inline;
  padding: 1px 10px;
  border-radius: 2px;
}

#hero p.excerpt{
  background-color: #000000;
  display: inline;
}

#hero p {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  color: #ffffff85;
  font-size: 1rem;
  margin-bottom: 0px
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

#hero .w-100 {
  height: 100%;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev {
  left: 0;
  transform: translate3d(0, 0, 0);
}

/*
#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 30px;
  line-height: 0;
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.5);
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
} */
/* 
#hero .carousel-control-next-icon:hover,
#hero .carousel-control-prev-icon:hover {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
} */

#hero .carousel-indicators li {
  cursor: pointer;
  background: #ffffffc9;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: 0.6;
  transition: 0.3s;
}

#hero .carousel-indicators li.active {
  opacity: 1;
  background: #f1c50e;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #ffffffc9;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: #f1c50e;
}

#hero .btn-get-started:hover {
  background: #df3740;
}

@media (max-width: 992px) {
  #hero {
    height: 50vh;
  }

  #hero .carousel-container {
    top: 8px;
  }
  #hero h2.entry-title {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 16px;
  }
  #hero h2.entry-title {
    font-size: 10px;
  }
}

@media (min-width: 1024px) {

  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

/*--------------------------------------------------------------
# Section countdown
--------------------------------------------------------------*/
#countdown {
  color: #ffffff;
  background-color: #1a1a1a;
  padding: 0px;
}

.countdown .count-box span {
  font-size: 3rem;
  text-align: center;
  display: block;
  font-weight: 700;
  color: #f1c50e
}

.countdown .count-box {
  color: #f2f2f2c9;
  background-color: transparent;
  border-right: 1px solid white;

}

.countdown .count-box.last-child {
  border: none;
}

.countdown .count-box p {

  text-align: center;

}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg,
.services .icon-box {
  background-color: #f8f9fa;
}


.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
  padding-bottom: 20px;
  position: relative;
  color: #fff;
}

.project .section-title h2 {
  font-weight: normal;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #d4e9ff;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs h2.aboutus-breadcrumb {
  color: white;
  font-size: 1.875rem;
  font-weight: 500;
}

.breadcrumbs {

  background: #f8f9fa;
  min-height: 40px;

}

.section-title-divider.aboutus {
  width: 113px;
}

.section-title-divider.news {
  width: 90px;
}

.breadcrumbs h2 {
  font-size: 32px;
  font-weight: 300;
  margin: 0;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6b7b8d;
  content: "/";
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 58px;
  }

  /*.breadcrumbs .d-flex {
    /* display: block !important; */
  /* } */

  .breadcrumbs h2 {
    margin-bottom: 10px;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.about .content h3 {
  font-weight: 500;
  line-height: 32px;
  font-size: 24px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding: 10px 0 0 28px;
  position: relative;
}

.about .content ul i {
  left: 0;
  top: 7px;
  position: absolute;
  font-size: 20px;
  color: #f1c50e;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding: 15px 0;
  text-align: center;
}

.clients img {
  max-width: 45%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
  filter: grayscale(100);
}

.clients img:hover {
  filter: none;
  transform: scale(1.15);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 40%;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 6px;
}

.services .icon-box i {
  float: left;
  color: #f1c50e;
  font-size: 40px;
}

.services .icon-box h4 {
  margin-left: 70px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .icon-box h4 a {
  color: #556270;
  transition: 0.3s;
}

.services .icon-box p {
  margin-left: 70px;
  line-height: 24px;
  font-size: 14px;
}

.services .icon-box:hover h4 a {
  color: #f1c50e;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #ffffffc9;
  background: #f1c50e;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(85, 98, 112, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(85, 98, 112, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #ffffffc9;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: rgba(255, 255, 255, 0.6);
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: white;
}

.portfolio .portfolio-wrap:hover::before {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #ffffffc9;
  opacity: 1;
  border: 1px solid #f1c50e;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #f1c50e;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(85, 98, 112, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Our Team
--------------------------------------------------------------*/
.team .member {
  position: relative;
  box-shadow: 0px 2px 15px rgba(85, 98, 112, 0.08);
  padding: 30px;
  border-radius: 4px;
  background: white;
}

.team .member .pic {
  overflow: hidden;
  width: 140px;
  border-radius: 4px;
}

.team .member .pic img {
  transition: ease-in-out 0.3s;
}

.team .member:hover img {
  transform: scale(1.1);
}

.team .member .member-info {
  padding-left: 30px;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #556270;
}

.team .member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}

.team .member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #dee2e6;
  bottom: 0;
  left: 0;
}

.team .member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}

.team .member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.team .member .social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  background: #8795a4;
}

.team .member .social a i {
  color: #ffffffc9;
  font-size: 16px;
  margin: 0 2px;
}

.team .member .social a:hover {
  background: #f1c50e;
}

.team .member .social a+a {
  margin-left: 8px;
}

/*--------------------------------------------------------------
# Our Skills
--------------------------------------------------------------*/
.skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}

.skills .progress .skill {
  padding: 10px 0;
  margin: 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #556270;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #edeff1;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #6b7b8d;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features {
  overflow: hidden;
}

.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  border: 0;
  padding: 12px 15px 12px 0;
  transition: 0.3s;
  color: #556270;
  border-radius: 0;
  border-right: 2px solid white;
  font-weight: 600;
  font-size: 15px;
}

.features .nav-link:hover {
  color: #f1c50e;
}

.features .nav-link.active {
  color: #f1c50e;
  border-color: #f1c50e;
}

.features .tab-pane.active {
  -webkit-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}

.features .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #556270;
}

.features .details p {
  color: #777777;
}

.features .details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .features .nav-link {
    border: 0;
    padding: 15px;
  }

  .features .nav-link.active {
    color: #ffffffc9;
    background: #f1c50e;
  }
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #ffffffc9;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.pricing h3 {
  font-weight: 400;
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #777777;
  background: #f8f8f8;
}

.pricing h4 {
  font-size: 36px;
  color: #f1c50e;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.pricing h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #444444;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing ul li {
  padding-bottom: 16px;
}

.pricing ul i {
  color: #f1c50e;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-wrap {
  margin: 20px -20px -20px -20px;
  padding: 20px 15px;
  background: #f8f8f8;
  text-align: center;
}

.pricing .btn-buy {
  background: #f1c50e;
  display: inline-block;
  padding: 8px 35px 9px 35px;
  border-radius: 4px;
  color: #ffffffc9;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  transition: 0.3s;
}

.pricing .btn-buy:hover {
  background: #e1444d;
}

.pricing .featured h3 {
  color: #ffffffc9;
  background: #f1c50e;
}

.pricing .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #f1c50e;
  color: #ffffffc9;
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-item {
  margin: 20px 0;
  padding: 20px 0;
  border-bottom: 1px solid white;
}

.faq .faq-item i {
  color: #dee2e6;
  font-size: 20px;
  float: left;
  line-height: 0;
  padding: 13px 0 0 0;
  margin: 0;
}

.faq .faq-item h4 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin: 0 0 10px 28px;
  font-family: "Poppins", sans-serif;
}

.faq .faq-item p {
  font-size: 15px;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 40px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #ffffffc9;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #ffffffc9;
  float: left;
  margin: 0 10px 0 0;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #f8d1d3;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px 0 0 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;
  background: #ffffffc9;
}

.contact .info i {
  font-size: 20px;
  color: #556270;
  float: left;
  width: 44px;
  height: 44px;
  background: #edeff1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #556270;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #8795a4;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #556270;
  color: #ffffffc9;
}

.contact .php-email-form {
  width: 100%;
  background: #ffffffc9;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #ffffffc9;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #ffffffc9;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #ffffffc9;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #f1c50e;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: #f1c50e;
  border: 0;
  padding: 10px 24px;
  color: #ffffffc9;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #e24d55;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}

.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
  max-height: 440px;
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #556270;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: #f1c50e;
}

.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #c1c8d0;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li+li {
  padding-left: 20px;
}

.blog .entry .entry-meta i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
}

.blog .entry .entry-meta a {
  color: #777777;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .entry .entry-content p {
  line-height: 24px;
}

.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #f1c50e;
  color: #ffffffc9;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
  background: #df3740;
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #444444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #556270;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
  color: #a4afba;
  display: inline;
}

.blog .entry .entry-footer a {
  color: #606f7e;
  transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
  color: #f1c50e;
}

.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .cats li {
  display: inline-block;
}

.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .entry .entry-footer .tags li {
  display: inline-block;
}

.blog .entry .entry-footer .tags li+li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}

.blog .entry .entry-footer .share {
  font-size: 16px;
}

.blog .entry .entry-footer .share i {
  padding-left: 5px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 120px;
  margin-right: 20px;
}

.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: #556270;
}

.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
  color: rgba(85, 98, 112, 0.5);
  margin-right: 5px;
}

.blog .blog-author p {
  font-style: italic;
  color: #b7b7b7;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
  width: 60px;
}

.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #444444;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: #f1c50e;
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #556270;
}

.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #6b7b8d;
  margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #e9797f;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #e9797f;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #556270;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #606f7e;
}

.blog .blog-pagination {
  color: #8795a4;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: #556270;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: #f1c50e;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: #ffffffc9;
}

.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #556270;
  position: relative;
}

.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}

.blog .sidebar .search-form form {
  background: #ffffffc9;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #f1c50e;
  color: #ffffffc9;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: #de323c;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li+li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: #556270;
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: #f1c50e;
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item+.post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: #556270;
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: #f1c50e;
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #aaaaaa;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #96a2af;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid white;
  display: inline-block;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #ffffffc9;
  border: 1px solid #f1c50e;
  background: #f1c50e;
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #fbfbfc;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
  background: #333333;
  padding: 0 0 30px 0;
  color: #ffffffc9;
  font-size: 14px;
}

#footer .footer-top {
  background: #333333;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  color: #ffffffc9;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffffc9;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #f1c50e;
  color: #ffffffc9;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #ffffffc9;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: white;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #ffffffc9;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px 2px 20px;
  background: #f1c50e;
  color: #ffffffc9;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type=submit]:hover {
  background: #df3740;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
}

#footer .credits a {
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
  font-weight: 600;
}

#footer .credits a:hover {
  color: white;
}



.scroll-to-top.show {
  opacity: 1;
}

.scroll-to-top box-icon {
  position: fixed;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  padding: 7px 5px 5px 7px;
  border-radius: 50%;
  background-color: #f1c50e;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
  transition: opacity 0.2s;
  position: fixed;
  bottom: 60px;
  right: 60px;
}
